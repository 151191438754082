import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Downloader from "../components/downloader"
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'
import Faqs from "../components/faqs"
import HowTo from "../components/howTo"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const IndexPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t("Online Twitter Video Downloader")} description={t("Download Twitter Videos in HD and Audio Formats")} />
      <Downloader title={t("Online Free Twitter Video Downloader")} description={t("Download Twitter Videos in HD and Audio Formats")} />
      <Container>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("Twitter Video Downloader Free")}</h2>
            <p>{t("Do you want to avoid the challenges that come with downloading videos from Twitter? If so, you can use our")} <Link to="/twitter-video-downloader/">{t("Twitter Video Downloader")}</Link> {t("to save videos from this well-known social network. For downloading videos from Twitter, you don't need to install any software because you can easily access this web-based utility through the web browser on your device.")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("How To Download Twitter Videos Online")}?</h2>
            <p>{t("There are a few different ways that you can download videos from Twitter")}:</p>
            <ol>
              <li>{t("Use a website that allows you to download Twitter videos. There are a number of websites that allow you to enter the URL of the Twitter video you want to download, and then download the video in a format of your choice (such as MP4). Some examples of websites that you can use to download Twitter videos include SaveTweetVid, TweetDownload, Tweet2MP4, and")} <Link to="/twitter-video-downloader/">{t("Snapzy Twitter Video Downloader")}</Link></li>
              <li>{t("Use a screen recording app to record the Twitter video as you are watching it. This will allow you to capture the video without any watermarks or other overlaid graphics, as they will not be present in the recording. You can use a screen recording app such as AZ Screen Recorder or DU Recorder for Android, or QuickTime Player for macOS.")}</li>
              <li>{t("Use a video downloader app to download the Twitter video directly to your device. There are a number of apps that allow you to download Twitter videos directly to your phone or tablet. Some examples of these apps include VidMate and Snaptube for Android, and MyMedia and Video Downloader for iOS.")}</li>
            </ol>
            <p>{t("It's important to note that downloading videos from Twitter without the permission of the copyright holder may be a violation of the terms of service for the platform, and could potentially be illegal. You should only download Twitter videos for personal, non-commercial use, and you should always respect the intellectual property rights of the content creators.")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("Questions About Twitter Video Downloader App")}</h2>
            <h5>{t("Are Twitter video downloaders legal")}?</h5>
            <p>{t("It's important to note that downloading videos from Twitter without the permission of the copyright holder may be a violation of the terms of service for the platform, and could potentially be illegal. You should only download Twitter videos for personal, non-commercial use, and you should always respect the intellectual property rights of the content creators.")}</p>
            <h5>{t("How do Twitter video downloaders work")}?</h5>
            <p>{t("Twitter video downloaders work by allowing you to enter the URL of the Twitter video you want to download, and then downloading the video in a format of your choice (such as MP4). Some Twitter video downloaders are websites that you can use in your web browser, while others are apps that you can download and install on your device.")}</p>
            <h5>{t("Can I download Twitter videos in high quality")}?</h5>
            <p>{t("Yes, many Twitter video downloaders allow you to download videos in high quality. Some Twitter video downloaders may offer a range of quality options, such as 360p, 480p, 720p, or 1080p. You can choose the quality level that best suits your needs.")}</p>
            <h5>{t("Is it possible to download Twitter videos on a computer")}?</h5>
            <p>{t("Yes, it is possible to download Twitter videos on a computer. You can use a website that allows you to download Twitter videos, or you can use a screen recording app to record the video as you are watching it. You can also use a video downloader app that is compatible with your computer's operating system, such as 4K Video Downloader for Windows or Mac.")}</p>
            <h5>{t("Can I download Twitter videos with the watermark intact")}?</h5>
            <p>{t("Yes, it is possible to download Twitter videos with the watermark intact. However, if you want to download the video without the watermark, you can try using a screen recording app to record the video as you are watching it, or you can use a video downloader app to download the video directly to your device.")}</p>
            <h5>{t("How to save/download twitter videos online")}?</h5>
            <p>{t("It takes only one step to download video Twitter online which is, you have to copy and paste the Twitter video link into our tool and it will automatically find you the download link of the tweet video from the 3rd party servers.")}</p>
            <h5>{t("How to download twitter videos on iPhone or android")}?</h5>
            <p>{t("Our Twitter video download link finder, Snapzy, works fine on all devices including android and iPhone mobiles and you can easily save your favourite tweet videos in your local storage if you own the right to the video.")}</p>
            <h5>{t("Is Snapzy a twitter video download bot")}?</h5>
            <p>{t("Snapzy only helps you to find the best download links for twitter and it doesn't work as a bot.")}</p>
          </Col>
        </Row>
      </Container>
      <HowTo />
      <Faqs />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
